.BgDiv{
    margin-top: -3%;
    
}

.AppBg{
    opacity: 0.5;
}



#webDesDiv{
    
    background-image: url('../../../assets/webDesHero.jpg');
    background-size: contain;
    background-attachment: fixed;
    background-position: center;
}
@media (max-width: 640px) {#webDesDiv{
    
    background-image: url('../../../assets/webDesHero.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}}