.contact{
    background-image: url('../../assets/contact2.jpg');
    margin-top: -5%;
    background-size: cover;
    
}

  .background h3 {
    display: flex;
    justify-content: flex-end; /* Align content to the right end */
    align-items: center; /* Center content vertically */
    margin-right: 50px; /* Add margin to the right */
    margin-left: auto; /* Move h3 to the right by pushing it to the maximum left */
  }
  
  .background h1 {
    display: flex;
    justify-content: flex-start; /* Align content to the left end */
    align-items: center; /* Center content vertically */
    margin-left: 20px; /* Add margin to the left */
    margin-right: auto; /* Move h1 to the left by pushing it to the maximum right */
    margin-top: 50px;
  }
  
  .about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Set the height of the container to full viewport height */
  }
  
  .background {
    text-align: center;
    margin-bottom: 20px; /* Adjust as needed */
  }
  
  .background h1 {
    font-weight: bold;
  }
  
  .title{
    margin-top: -5%;
  }

  

.desc{
  margin-top: 3%;
  margin-bottom: 17%;
}