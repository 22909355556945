.BgDiv{
    margin-top: -3%;
    
}

.AppBg{
    opacity: 0.5;
}



#logoDesHero{
    background-image: url('../../../assets/logoDesHero.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}


@media (max-width: 640px) {#logoDesHero{
    
    background-image: url('../../../assets/logoDesHero.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}}